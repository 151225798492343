<template>
  <v-main>
    <v-row>
      <v-progress-linear
        :indeterminate="true"
        v-if="loading"
      ></v-progress-linear>
      <v-col>
        <p class="text-h5 mt-4">主新闻中心（MPC）</p>
        <div v-if="mpcs.length > 0">
          <div v-for="(mpc, index) in mpcs" :key="index">
            <v-card class="mb-4">
              <v-card-title primary-title> {{ mpc.title }} </v-card-title>
              <v-card-subtitle>
                {{ mpc.press }} <br />
                {{ mpc.time }}
              </v-card-subtitle>
              <v-card-text>
                <span v-if="content[index].readmore"
                  >{{ content[index].content.slice(0, 100) }}
                </span>
                <a
                  class=""
                  v-if="content[index].readmore"
                  @click="content[index].readmore = false"
                  href="#"
                >
                  Read more...
                </a>
                <span
                  v-if="!content[index].readmore"
                  v-html="content[index].content"
                ></span>
              </v-card-text>
            </v-card>
            <div
              v-if="isPro === false"
              class="text-h5 text-center primary--text"
            >
              如需查看超过三条MPC发布内容，请联系组委解锁模时PRO功能。感谢支持。♥️
            </div>
          </div>
        </div>
        <div v-else>尚未发布内容</div>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import db from "@/plugins/leancloud";
export default {
  name: "mpc",
  metaInfo() {
    return {
      title: "MPC",
    };
  },
  props: ["id"],
  data() {
    return {
      loading: false,
      content: [],
      mpcs: [],
      isPro: false,
    };
  },
  watch: {
    mpcs() {
      this.calculateReadmore();
    },
    isPro() {
      if (this.isPro) {
        console.log("Pro");
      } else {
        this.mpcs = this.mpcs.slice(0, 3);
      }
    },
  },
  methods: {
    calculateReadmore() {
      let d = [];
      if (this.mpcs.length > 0) {
        d = this.mpcs.map((item) => {
          let o = {
            content: item.content,
            readmore: item.content.length > 100,
          };
          return o;
        });
      }
      this.content = d;
    },
  },
  mounted() {},
  created() {
    this.loading = false;
    const query = new db.Query("sessionConferences");
    query
      .equalTo("objectId", this.id)
      .include("dependent")
      .select(["mpcs", "dependent.pro"])
      .find()
      .then((resp) => {
        const obj = JSON.parse(JSON.stringify(resp[0]));
        this.mpcs = obj.mpcs;
        this.isPro = obj.dependent.pro;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<i18n>
{
  "en": {
    "account": "Account",
    "reset": "Reset",
    "resetConfirm":"Are you sure to clear data and restart?",
    "confirm": "Confirm",
    "about-session": "About Session",
    "about-munshare": "About Munshare",
    "immediateUse":"Chair MUN Online",
    "login":"Login",
    "munshare": "Munshare",
    "showPro": "Show Pro Logo",
    "hidePro": "Hide Pro Logo",
    "resetSession":"Clear the data and restore Session",
    "showConferenceName": "Display your conference name",
    "darkMode":"Dark Mode",
    "setTheme":"Set Theme",
    "themeColor":"Theme Color",
    "Thanks":"Thanks for your support"

  },
  "zh": {
    "account": "个人中心",
    "reset": "重置",
    "resetConfirm":"确定清除所有数据并重启App么？",
    "about-session": "关于模时Session",
    "confirm": "确定",
    "about-munshare": "关于模时",
    "showConferenceName": "会议名称展示",
    "showPro": "显示Pro Logo",
    "hidePro": "隐藏Pro Logo",
    "munshare": "模时",
    "immediateUse":"随开随用的模联议程",
    "resetSession":"重置Session全部数据，恢复原始状态。",
    "login":"登录",
    "setTheme":"主题设置",
    "darkMode":"夜间模式",
    "themeColor":"主题颜色",
    "Thanks":"感谢你使用Session Pro。如有任何使用问题，请联系模时。"
  }
}
</i18n>
